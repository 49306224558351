import styled from "styled-components";
import logo from '../../assets/img/panda_logo.png'
import DropDown from "../../components/Dropdown";
import { useState,useRef,useEffect } from "react";
import React from "react";
import { CommunityUpdateAPI,CommunityUpdateParams } from "../api/communityApi";
import { SelectCommunityParams } from "../../reducers/communitySlice";


const CommunityProfile = ({icon, name,description,id,banner,visibility}:SelectCommunityParams) => {

    const editList = ['이름 변경', '배경 변경', '프로필 변경']
    const [view, setView] = useState<boolean>(false);
    const dropDownRef = React.useRef<HTMLDivElement>(null)
    const editButtonRef = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event:any) => {
            if (dropDownRef.current &&
                editButtonRef.current &&
                !editButtonRef.current.contains(event.target)) {
                setView(false); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleButtonClick = () => {
        setView((prev) => !prev); // view 상태를 토글
    };
    
    useEffect(() => {
        if (view && dropDownRef.current) {
            dropDownRef.current.style.height = `${dropDownRef.current.scrollHeight}px`; // 내용 높이로 설정
        } else if (dropDownRef.current) {
            dropDownRef.current.style.height = '0px'; // 다시 0으로 설정
        }
    }, [view])

    return(
        <>
            <CommunityInfoContainer>
                <ProfileCircle>
                    <ProfileImage src = {icon === null ? logo : `${icon}`} alt ='Description'/>
                </ProfileCircle>
                <CommunityNameWrapper>
                    <CommunityName onClick = {() => {CommunityUpdateAPI({name: '커뮤니티 수정 테스트', id:id, description:description, visibility:visibility})}}>
                        {name}
                    </CommunityName>
                </CommunityNameWrapper>
                <EditButton 
                ref = {editButtonRef}
                onClick = {() => {handleButtonClick()}}>
                    <EditIcon src="https://img.icons8.com/material-outlined/24/menu-2.png" alt="menu-2"/>
                </EditButton>

                {view && (
                <DropDownElement>
                    <DropDown 
                    ref = {dropDownRef}
                    menu = {editList}
                    />
                </DropDownElement>)}
            </CommunityInfoContainer>
        </>
    )
}

const CommunityInfoContainer = styled.div`
    display: flex;
    top: 35vh;
    left: 25%;
    position: absolute;
`

const ProfileCircle = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 75px;
    display: flex;
    border: 2px solid black;
`

const ProfileImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 75px;
`

const CommunityNameWrapper = styled.div`
    top: 40vh;
    display: flex;
    margin-top: 14vh;
`

const CommunityName = styled.h1`
    font-size: 2em;
    color: #333;
`

const EditButton = styled.div`
    position: absolute;
    top: 18vh;
    left: 55vw
`

const EditIcon = styled.img`
    cursor: pointer;
    width: 24px;
    height: 24px;
`

const DropDownElement = styled.div`
    position: absolute;
    top: 20vh;
    left: 47.5vw
`

export default CommunityProfile;