import React, { useRef, useState, useEffect } from 'react';
import { FaSistrix, FaPlus, FaBell } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserModalForm from '../User/UserModalForm';
import logo from '../../assets/img/panda_logo.png';
import ProfileModal from '../User/ProfileModal';
import { AddSearchAPI } from '../api/searchApi';
import NotificationModal from '../User/NotificationModal';
import { searchQuery, setSearchResults } from '../../reducers/searchSlice';
import { RootState, AppDispatch } from '../../store/store';
import debounce from 'lodash.debounce';
import { UserModalState, setModalState } from '../../reducers/modalStateSlice';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import './GlobalBar.module.css';
import styled from 'styled-components';

const GlobalBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const modalState: UserModalState = useSelector(
    (state: RootState) => state.modalState,
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const searchResults = useSelector(
    (state: RootState) => state.search.searchResults,
  );
  const postSubmit = () => navigate('/boards/submit');
  const [userHover, setUserHover] = useState<boolean>(false);
  const [bellHover, setBellHover] = useState<boolean>(false);
  const [logoHover, setLogoHover] = useState<boolean>(false);
  const [plusHover, setPlusHover] = useState<boolean>(false);
  const [inquiryHover, setInquiryHover] = useState<boolean>(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);
  const userButtonRef = useRef<HTMLDivElement>(null);
  const bellButtonRef = useRef<HTMLDivElement>(null);

  const toggleProfileModal = () => {
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  const openModal = () => {
    dispatch(setModalState(!modalState.modalState));
  };

  const debouncedSearch = debounce((value: string) => {
    if (value.length > 2) {
      dispatch(setSearchResults([value]));
    }
  }, 300); // 300ms 디바운싱

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value); // Use debounced search
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      clickSearch();
    }
  };

  const clickSearch = async () => {
    if (!searchTerm) {
      // 나중에는 alert 제거하기
      alert('내용을 입력해주세요');
      return;
    }

    await AddSearchAPI({ query: searchTerm });
    navigate(`/search/list?query=${searchTerm}`);
  };

  const toggleNotificationModal = () => {
    setIsNotificationModalOpen(!isNotificationModalOpen);
  };

  const handleAddTopic = (topic: string) => {
    setSearchTerm(topic);
    navigate(`/search/list?query=${topic}`);
  };

  const handleLogoClick = () => {
    window.location.href = '/';
  };

  useEffect(() => {
    if (isProfileModalOpen === false && modalState.modalState === true) {
      openModal();
    }
  }, [isProfileModalOpen]);

  useEffect(() => {
    if (isNotificationModalOpen === false && modalState.modalState === true) {
      openModal();
    }
  }, [isNotificationModalOpen]);

  return (
    <div>
      <GlobalTopBar modalState={modalState.modalState}>
        {/* Logo and Site Name */}
        <LogoWrapper
          logoHover={logoHover}
          onMouseEnter={() => setLogoHover(true)}
          onMouseLeave={() => setLogoHover(false)}
          onClick={handleLogoClick}
        >
          <LogoImage src={logo} alt="Logo" />
          <SiteName>{'jaychis.com'}</SiteName>
        </LogoWrapper>

        {/* Search Bar */}
        <SearchContainer>
          <SearchInput
            type="search"
            placeholder="Search"
            value={searchTerm}
            style={{ width: '35%', padding: '10px', borderRadius: '20px' }}
            name={'search'}
            onChange={(e) => handleSearchChange(e)}
            onKeyDown={handleKeyDown} // 엔터 키 이벤트 추가
          />
          <SearchIcon onClick={clickSearch} />
        </SearchContainer>

        {/* Navigation Icons */}
        <LoginStatusView>
          {localStorage.getItem('access_token') ? (
            <>
              <ProfileButton
                ref={userButtonRef}
                userHover={userHover}
                onMouseEnter={() => setUserHover(true)}
                onMouseLeave={() => setUserHover(false)}
                onClick={toggleProfileModal}
              >
                <ProfileImage src={logo} alt="Profile" onClick={openModal} />
              </ProfileButton>
              <ProfileModal
                isOpen={isProfileModalOpen}
                onRequestClose={toggleProfileModal}
                buttonRef={userButtonRef}
              />

              <PostButtonContainer
                plusHover={plusHover}
                onMouseEnter={() => setPlusHover(true)}
                onMouseLeave={() => setPlusHover(false)}
              >
                <SubmitButton plusHover={plusHover} onClick={postSubmit}>
                  <Tooltip id="tooltip" place="top" arrowColor="transparent" />
                  <PlusIcon
                    data-tooltip-content="글쓰기"
                    data-tooltip-id="tooltip"
                  />
                </SubmitButton>
              </PostButtonContainer>

              {/* Plus/Create Icon */}
              <InquiryButtonContainer
                inquiryHover={inquiryHover}
                onMouseEnter={() => setInquiryHover(true)}
                onMouseLeave={() => setInquiryHover(false)}
              >
                <InquiryButton
                  inquiryHover={inquiryHover}
                  onClick={() => navigate('/users/inquiry')}
                >
                  <Tooltip id="tooltip2" place="top" arrowColor="transparent" />
                  <InquiryIcon
                    data-tooltip-content="문의하기"
                    data-tooltip-id="tooltip2"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVR4nO3Yz4uNURzH8df4kfyakowsLGQU2diIiMjKhkiilJ3ZmRr/gFhYs5iFslMWSikLWSkZUjY2QpRQfqc0fvPoqe/iaRjm3rn3Pj867zrdOs/5fp9z7nnO5/v9HhKJRCJREllDmsYtpK5kjV3IoPox+LeFvMMW9WEDXk122L/ggOqzB+OTHfbR+P2JEdVlJOZYnPMfh324MOgsZqkOM3Em5vYLx/+nWnvxKfquYqHymY/Lhc//4FTldyNeR/8dLFUei3GzIEhbW40jK/Egnj3BGuXI68OYw2Osbjcg5v/GjXj+Htv0jk14E+++jYHpRvY5uBBjvuKQ7rMPn+OdlzCvUylKX6jERMXoBsMF5TyNGd3ItY7ge4w/h9k6K6+j4fsHjnY7adxdiKrX0G/6LMCV8Jl/Uvt7lf2ux8uwu4fl2mcZ7oavt9jc6zR+Be6H7Qusa8PHWjwNH4+wqqx6ZBGuh/1H7GzBdgc+hO0YlpRdWOXyfD585EIwNAWbw/gWNhcxtyoVYh9OFeT5RKjQRPK+kzEmC5vctnKl7lBBnvP8aFdE5IFQu7EWd67Umn07nv/jtuNZjKnF5UM/jkWONB7tVhRFnS4LssbeotSVLC2kYmRpRypGlnakYmSN3ZGs5k1jFpJIJBIJveY3S2K8l4EjqFIAAAAASUVORK5CYII="
                  />
                </InquiryButton>
              </InquiryButtonContainer>

              <NotificationButtonContainer
                bellHover={bellHover}
                onMouseEnter={() => setBellHover(true)}
                onMouseLeave={() => setBellHover(false)}
                onClick={() => {
                  toggleNotificationModal();
                  openModal();
                }}
              >
                <BellIcon bellHover={bellHover} />
              </NotificationButtonContainer>

              <NotificationModal
                isOpen={isNotificationModalOpen}
                onRequestClose={toggleNotificationModal}
                buttonRef={bellButtonRef}
              />
              {/* Notification Icon */}
            </>
          ) : (
            <>
              <UserModalForm />
            </>
          )}
        </LoginStatusView>
      </GlobalTopBar>
    </div>
  );
};

export default GlobalBar;

const GlobalTopBar = styled.nav<{ modalState: boolean }>`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 0.625rem;
  border: 2px solid #d3d3d3;
  width: 100%;
  z-index: ${(props) => (props.modalState ? -1 : 2000)};
`;

const LogoWrapper = styled.div<{ logoHover: boolean }>`
  display: flex;
  align-items: center;
  background: ${(props) => (props.logoHover ? '#D3D3D3' : 'transparent')};
  border-radius: 25px;
  padding: 0.625rem; /* 10px을 rem으로 변환 */
  cursor: pointer;
`;

const LogoImage = styled.img`
  width: 50px;
`;

const SiteName = styled.span`
  margin-left: 0.625rem; /* 10px을 rem으로 변환 */
`;

const SearchContainer = styled.div`
  flex-grow: 1;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  position: relative; /
`;

const SearchInput = styled.input`
  width: 35%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
`;

const SearchIcon = styled(FaSistrix)`
  margin-right: 20px;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  cursor: pointer;
`;

const ProfileButton = styled.div<{ userHover: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: ${(props) => (props.userHover ? '#D3D3D3' : 'transparent')};
  cursor: pointer;
  position: relative;
  margin-top: 0.5vh;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 2;
`;

const PostButtonContainer = styled.div<{ plusHover: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 25px;
  margin-left: 5px;
  background: ${(props) => (props.plusHover ? '#D3D3D3' : 'white')};
`;

const SubmitButton = styled.button<{ plusHover: boolean }>`
  border: none;
  background: ${(props) => (props.plusHover ? '#D3D3D3' : 'white')};
  cursor: pointer;
`;

const PlusIcon = styled(FaPlus)`
  height: 30px;
  width: 15px;
`;

const InquiryButtonContainer = styled.div<{ inquiryHover: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 25px;
  background: ${(props) => (props.inquiryHover ? '#D3D3D3' : 'white')};
`;

const InquiryButton = styled.button<{ inquiryHover: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 16px;
  border-radius: 25px;
  background: ${(props) => (props.inquiryHover ? '#D3D3D3' : 'white')};
  cursor: pointer;
`;

const InquiryIcon = styled.img`
  height: 55%;
  width: 55%;
`;

const NotificationButtonContainer = styled.div<{ bellHover: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: ${(props) => (props.bellHover ? '#D3D3D3' : 'transparent')};
  cursor: pointer;
  margin-right: 30px;
`;

const BellIcon = styled(FaBell)<{ bellHover: boolean }>`
  color: ${(props) => (props.bellHover ? 'white' : 'black')};
  width: 20px;
  height: 20px;
`;

const LoginStatusView = styled.div`
  display: flex;
  alignitems: center;
`;
